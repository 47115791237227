










import Vue from 'vue'
import Layout from "@/router/layouts/main.vue"

export default Vue.extend({
  components: { Layout }
})
